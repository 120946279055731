<template>
    <div class="consumeRecord">
        <!-- 消费记录 -->
        <div class="partition_title">
            <div>时长统计</div>
            <hr />
        </div>
    </div>
</template>
<script>

export default {
    name: "ConsumeRecord",
    data() {
        return {
        
        };
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
    // 缺省图
    .defaultPage{
        width: 100%;
        margin-top: 40px;
        // background-color: navy;
        .defaultPage_content{
            width: 260px;
            height: 240px;
            margin: 0 auto;
            text-align: center;
            color: #ADADB0;
        }
    }

    // 隔断标题
    .partition_title{
        height: 22px;
        font-size: 16px;
        color: #606062;
        >div{
            width: 9.4%;
            float: left;
        }
        hr{
            width: 80%;
            float: left;
            padding-right: 5%;
            display: inline-block;
            background: #FAD8A1;
            opacity: 0.5;
        }
    }
</style>
